export const hero = {
  title: "Self service kiosk POS terminal to serve more guests, faster",
  subTitle: "Enterprise Venue Kiosks",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/kiosks/demo",
  },
};

export const heroImage = {
  imageName: "products-kiosks-hero.png",
};

export const secondaryHeroTwoCols = {
  title: "Stand up to the demands of fast-paced self-service",
  subtext:
    "Select the kiosk POS hardware that meets the needs of your enterprise with countertop, freestanding, and outdoor models that feature 15″ and 22″ screen size options. Round out your self-service setup with reliable peripheral support including barcode scanners, printers, and EMV devices.",
  image: {
    imageName: "products-kiosks-secondary-hero-2.png",
    mobileImageName: "products-kiosks-secondary-hero-2-mobile.png",
    title: "We’re dedicated to your success",
    subtext: "KIOSKS",
  },
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/kiosks/demo",
  },
};

export const simpleText = {
  title: "Reduce lines and increase transaction size",
  subtext:
    "User-friendly kiosks streamline the ordering and payments process for guests even during interruptions in connectivity. Implement an ADA-compliant solution with both portrait and landscape screen options. Take advantage of custom branding opportunities that earn you additional revenue.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/kiosks/demo",
  },
};

export const secondaryHeroTitle = {
  title: "Give guests more control with self-service kiosks",
  subtext:
    "Eliminate lines, serve more guests while maximizing labor, and give customers what they want with a highly customizable kiosk experience.",
  imageName: "products-kiosks-secondary-hero-1.png",
  mobileImageName: "products-kiosks-secondary-hero-1-mobile.png",
};

export const productOverview = {
  subSections: [
    {
      title: "Intuitively designed with the guest in mind",
      subtext:
        "Special features such as quick add buttons, favorites, and a scrolling add-to-cart button are designed to make ordering fast and easy for guests. With custom branding and an intelligently designed interface, guests can find, scan, or weigh their items and checkout in minutes.",
      imageName: "products-kiosks-product-1.png",
      cta: {
        title: "Learn more",
        target: "/enterprise/kiosks/demo",
      },
    },
    {
      title: "Drive revenue with kiosks that never forget to upsell",
      subtext:
        "Introduce guests to their next favorite item with self-service software that always suggests combos, add-ons, and upsell items. Plus, image-based interfaces provide you with an opportunity to offer vendors advertising and promotional space.",
      imageName: "products-kiosks-product-2.png",
      cta: {
        title: "Learn more",
        target: "/enterprise/kiosks/demo",
      },
    },
  ],
};

export const highlightWithImage = {
  title: "Accept the latest and most secure ways to pay",
  subtext:
    "Check guests out faster without the need for staff interaction using safe and secure electronic payment methods, including mobile wallets or stored and loaded value options.",
  imageName: "products-kiosks-product-3.png",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/kiosks/demo",
  },
};

export const productSlides = {
  title: "An inside look at your new self-service kiosk system",
  slides: [
    {
      title: "Attract more guests",
      subtext:
        "Appeal to guests with a branded experience that empowers them to place their own order.",
      imageName: "products-kiosks-slides-1.png",
    },
    {
      title: "Quick order functionality",
      subtext:
        "Guests can quickly add items to their cart with an intuitive ordering workflow that includes image-based menus and item modifiers, with embedded upsells.",
      imageName: "products-kiosks-slides-2.png",
    },
    {
      title: "Convenient payment options",
      subtext:
        "Let your guests choose how they want to pay—with credit, debit, mobile wallet, and loaded or stored value payment options.",
      imageName: "products-kiosks-slides-3.png",
    },
    {
      title: "Instant receipt and order confirmation",
      subtext:
        "Guests can choose to receive printed or digital receipts and order confirmation, then proceed to pick up their order.",
      imageName: "products-kiosks-slides-4.png",
    },
  ],
};

export const productsWithLinks = {
  title: "Built on a true omnichannel ecosystem",
  products: [
    {
      title: "Point-of-Sale",
      description:
        "Reduce wait times with a user-friendly POS solution that processes orders and payments fast, even when offline.",
      linkTo: "/enterprise/pos-terminals",
      imageName: "products-kiosk.png",
    },
    {
      title: "Handhelds",
      description:
        "Take orders and process payments courtside, tableside, or while guests are still in line using on-the-go point of sale solutions.",
      linkTo: "/enterprise/handhelds",
      imageName: "products-handheld.png",
    },
    {
      title: "Kitchen Displays",
      description:
        "Instantly transmit all POS orders to a centralized device for preparation, even if your internet connection goes down.",
      linkTo: "/enterprise/kitchen-displays",
      imageName: "products-kitchen-display.png",
    },
  ],
};

export const footer = {
  title: "Book a consultation",
  subtext:
    "See for yourself how our enterprise cloud POS and management platform can transform the guest experience, provide valuable business insights, and deliver ROI to your enterprise.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/kiosks/demo",
  },
};
