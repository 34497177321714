import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  hero,
  heroImage,
  secondaryHeroTwoCols,
  simpleText,
  secondaryHeroTitle,
  productOverview,
  highlightWithImage,
  productSlides,
  productsWithLinks,
  footer,
} from "../../../data/appetize/kiosks";
import { enterpriseSupport } from "../../../data/spoton-contacts";

const SimpleHero = loadable(() =>
  import("../../../components-v2/Sections/Hero/SimpleHero")
);
const LargeImageFullWidth = loadable(() =>
  import("../../../components-v2/Sections/LargeImageFullWidth")
);
const SecondaryHeroTitleTwoCols = loadable(() =>
  import("../../../components-v2/Sections/Hero/SecondaryHeroTitleTwoCols")
);
const SimpleTitleWithDescription = loadable(() =>
  import("../../../components-v2/Sections/SimpleTitleWithDescription")
);
const SecondaryHeroTitle = loadable(() =>
  import("../../../components-v2/Sections/Hero/SecondaryHeroTitle")
);
const ProductOverview = loadable(() =>
  import("../../../components-v2/Sections/ProductOverview")
);
const ProductSlides = loadable(() =>
  import("../../../components-v2/Sections/ProductSlides")
);
const ProductsWithLinksPlainBackground = loadable(() =>
  import("../../../components-v2/Sections/ProductsWithLinksPlainBackground")
);
const FooterHero = loadable(() =>
  import("../../../components-v2/Sections/Hero/FooterHero")
);
const HighlightWithImageAndOffsetText = loadable(() =>
  import("../../../components-v2/Sections/HighlightWithImageAndOffsetText")
);

const EnterpriseKiosks = () => {
  return (
    <Layout className="non-fixed-width" {...enterpriseSupport}>
      <SEO
        loadChat={false}
        title="Self Service Kiosk Point of Sale (POS)  System | SpotOn"
        description="Mobile POS terminal kiosk systems from SpotOn modernize the customer journey with all-in-one self-service software. Eliminate lines, serve more guests, enhance the experience."
        cannonical="https://www.spoton.com/enterprise/kiosks/"
      />
      <SimpleHero className="mt-32 lg:mt-40" {...hero} />

      <LargeImageFullWidth className="mt-16 lg:mt-20" {...heroImage} />

      <SecondaryHeroTitle className="mt-14 lg:mt-28" {...secondaryHeroTitle} />

      <ProductOverview
        className="mt-20 lg:mt-36"
        {...productOverview}
        noMainTitle
        offsetText
        flipItems
      />

      <HighlightWithImageAndOffsetText
        className="mt-20 lg:mt-48"
        {...highlightWithImage}
      />

      <ProductSlides className="mt-20 lg:mt-36 xl:-mt-20" {...productSlides} />

      <SimpleTitleWithDescription className="mt-20 lg:mt-36" {...simpleText} />

      <SecondaryHeroTitleTwoCols
        className="mt-20 lg:mt-36"
        {...secondaryHeroTwoCols}
      />

      <ProductsWithLinksPlainBackground
        className="mt-20 lg:mt-36"
        {...productsWithLinks}
      />

      <FooterHero className="my-20 lg:my-36" {...footer} />
    </Layout>
  );
};

export default EnterpriseKiosks;
